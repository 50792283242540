import React from 'react'
import Section1 from '../components/Home/Section1'
import Section2 from '../components/Home/Section2'
import Section3 from '../components/Home/Section3'
import Section3Carousel from '../components/Home/Section3Carousel'
import Section4 from '../components/Home/Section4'
import Section5 from '../components/Home/Section5'
import Section6 from '../components/Home/Section6'
import './Home.css'

function Home() {
    return (
        <div className='Home'>
            <Section1 />
            <Section2 />
            {/* <Section3 /> */}
            <Section3Carousel />
            <Section4 />
            <Section5 />
            <Section6 />
        </div>
    )
}

export default Home
