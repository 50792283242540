import { Link } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import '../App.css'

function Navbar() {
    return (
        <Container>
            <Wrap>
                <a href="/"><Logo src={'/assests/Logo.png'} /></a>

                <Other>
                    <a href="https://discord.com/invite/otherorigins"><SocialMedia className='social_media'><img src="/assests/Vector.png" alt="" /></SocialMedia></a>
                    <a href="https://twitter.com/other_origins"><SocialMedia><img src="/assests/twitter.png" alt="" /></SocialMedia></a>
                    {/* <a class="connect_btn" href="#"><ConnectButton>Connect wallet</ConnectButton></a> */}
                </Other>
            </Wrap>
        </Container>
    )
}

export default Navbar
const Container = styled.header`

`

const Wrap = styled.div`
    padding: 48px 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`

const Logo = styled.img`
    
   // @media(max-width: 700px){
        width: 148px;
    }
`
const Links = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin: 16px 0;
    color: #FFFFFF; 
    }
`
const Other = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
`

const SocialMedia = styled.div`
    height: 48px;
    width: 48px;
    border-radius: 0px;
    border: 1px solid #FFFFFF;
    position: relative;
    // background: #000;
    display: flex;
    justify-content: center;   
    align-items: center; 
    margin: 0 10px;
    transition: 0.3s;
    :hover {
        background: #7877e6 !important;
      }
    img {
        height: 12px;
        width: 16px;
        border-radius: 0px;
    }
    @media(max-width: 700px){
        height: 36px;
        width: 36px; 
        img {
            height: 12px;
            width: 18px;
        }
    }
    
    @media(max-width: 460px){
        height: 0;
        width: 0;
        img {
            height: 12px;
            width: 18px; 
        }
    }
`

const ConnectButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    // padding: 15px 28px 14px;
    width: 144px;
    height: 52px;
    border-radius: 0px;
    border: 1px solid #FFFFFF;
    margin: 10px 10px;
    background: #FFFFFF;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    outline-width: 0px;
    cursor: pointer;
    transition: 0.3s;
    :hover {
        background: #7877e6 !important;
        color: white;
      }
    // line-height: 111.5%;
    @media(max-width: 700px){
        min-width: 144px;
        max-width: 128px;
        width: 100%;
        font-size: 14px;
    }
`