import React from 'react'
import styled from 'styled-components'

function Section2() {
    return (
        <Container>
            <Wrap>
                <SubWrapper>
                    <Left>
                        <Title>Other Invaders</Title>
                        <Description>
                            <p>The Other Invaders are a collection of randomly generated NFT characters exploring the Solana blockchain. It continues the lore of the initial <a href="https://www.otherorigins.com/">100 Other Origins Collection</a></p>

                            <p>The Invaders are created from over 300+ hand-drawn traits, resulting in millions of variations that make each Invader 1/1 unique. There are only 4,444 Invaders in existence.</p>

                            <p>There are 10 different races based on the 10 Origin types. You can see the rarity of each race below.</p>

                            <p>Origin holders aka “Originators” are guaranteed an exclusive Invader from the Origin type they hold.</p>
                        </Description>
                    </Left>
                    <Right>
                        <img src="assests/Invader.gif" alt="" />
                    </Right>
                </SubWrapper>
            </Wrap>
        </Container>
    )
}

export default Section2

const Container = styled.section`
    min-height: 10vh;
    background: #02080d;
    // min-width: 100vw;
`   

const Wrap = styled.div`
    margin: 64px 64px;
    display: flex;
    justify-content: center;
    @media(max-width: 800px) {
        margin: 64px 32px;
    }
`

const SubWrapper = styled.div`
    max-width: 1100px;
    width: 100%;
    min-width: 200px;
    display: flex;
    justify-content: space-evenly;
    @media(max-width: 800px) {
        flex-direction: column;
        align-items: center;
    }
`

const Left = styled.div`

`

const Title = styled.div`
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    color: #7877e6;
    @media(max-width: 1100px) {
        font-size: 36px;
    }
`

const Description = styled.div`
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 27px;
    letter-spacing: 0em;
    
    text-align: justified;
    color: #ffffff;
    max-width: 610px;
    width: 100%;
    min-width: 200px;
    @media(max-width: 1100px) {
        font-size: 18px;
    }
`

const Right = styled.div`
    img {
        width: 410px;
        height: 410px;
    }
    @media(max-width: 1100px) {
        img {
            max-width: 410px;
            max-height: 410px;
            width: 100%;
            height: auto;
            min-width: 240px;
            min-height: 240px;
        }
    }
    @media(max-width: 800px) {
        margin-top: 64px;
    }
`